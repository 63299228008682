<template>
  <div>
    <van-nav-bar
      title="个人对比报告"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="box">
      <div class="testresult">
        <div class="testresult-title">
          <div>个人对比报告</div>
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/dushuren.png"
            alt=""
          />
        </div>
        <div class="gongqingnengli">
          <div class="ctx-title">
            <div class="title-ctx">共情能力</div>
            <div class="title-fk"></div>
          </div>
          <div class="" style="display: flex">
            <div id="echats1"></div>
            <div id="echats2"></div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 40px;
              margin-bottom: 10px;
              font-size: 15px;
            "
          >
            <div>首次测评结果</div>
            <div>本次测评结果</div>
          </div>
          <div class="gongqingnenglictx margintop20">
            共情能力总分区间为1-5分，您本次的共情能力总分是{{
              (
                (after[3][6].score + after[3][7].score + after[3][8].score) /
                3
              ).toFixed(2)
            }}分，处于中等水平，比前测结果有所提升。共情能力总分和各维度得分均为分数越高，能力越强。共情能力分为情绪识别能力、共情接纳能力、共情行为能力。
          </div>
          <div class="gongqingnenglictx2">
            <div class="ctx2-title">各维度解析</div>
            <div id="echats3" style="width: 100%; height: 350px"></div>
            <div class="margintop20 ctxtitle">【情绪识别维度】</div>
            <div class="ctxcontent">
              您的{{ after[3][6].entityName }}得分为{{
                after[3][6].score
              }}分。{{ after[3][6].entityExplain }}
            </div>
            <div class="ctxcontent">
              您的{{ after[3][0].entityName }}得分为{{
                after[3][0].score
              }}分。{{ after[3][0].entityExplain }}
            </div>

            <div class="ctxcontent">
              您的{{ after[3][1].entityName }}得分为{{
                after[3][1].score
              }}分。{{ after[3][1].entityExplain }}
            </div>

            <div class="ctxtitle">【共情接纳维度】</div>
            <div class="ctxcontent">
              您的{{ after[3][7].entityName }}得分为{{
                after[3][7].score
              }}分。{{ after[3][7].entityExplain }}
            </div>
            <div class="ctxcontent">
              您的{{ after[3][2].entityName }}得分为{{
                after[3][2].score
              }}分。{{ after[3][2].entityExplain }}
            </div>

            <div class="ctxcontent">
              您的{{ after[3][3].entityName }}得分为{{
                after[3][3].score
              }}分。{{ after[3][3].entityExplain }}
            </div>

            <div class="ctxtitle">【共情行为维度】</div>
            <div class="ctxcontent">
              您的{{ after[3][8].entityName }}得分为{{
                after[3][8].score
              }}分。{{ after[3][8].entityExplain }}
            </div>
            <div class="ctxcontent">
              您的{{ after[3][4].entityName }}得分为{{
                after[3][4].score
              }}分。{{ after[3][4].entityExplain }}
            </div>

            <div class="ctxcontent">
              您的{{ after[3][5].entityName }}得分为{{
                after[3][5].score
              }}分。{{ after[3][5].entityExplain }}
            </div>
          </div>
        </div>
        <div class="qingtingnengli">
          <div class="ctx-title">
            <div class="title-ctx">共情式倾听能力</div>
            <div class="title-fk"></div>
          </div>
          <div>
            共情式倾听能力均分：{{
              (
                (after[0][1].score + after[0][1].score + after[0][2].score) /
                3
              ).toFixed(2)
            }}分
          </div>
          <div
            id="echats4"
            style="width: 150px; height: 150px; margin: 0 auto"
          ></div>

          <div style="text-align: center">首次测评结果</div>
          <div
            id="echats5"
            style="width: 150px; height: 150px; margin: 0 auto"
          ></div>
          <div style="text-align: center">本次测评结果</div>
          <div class="content-sizi ctxcontent">
            您本次的共情式倾听能力总分为{{ after[0][2].score }}分，{{
              after[0][2].entityExplain
            }}
          </div>
          <div class="qtnl-ctx">
            <div class="qtnl-ctx-title">倾听准确性</div>
            <div id="echats6" style="width: 100%; height: 350px"></div>

            <div class="content-sizi ctxcontent">
              您的{{ after[0][0].entityName }}得分为{{
                after[0][0].score
              }}分。{{ after[0][0].entityExplain }}
            </div>
            <!-- <div class="qingtingnengli-xx">
              {{ after[0][0].entityAd }}
            </div> -->
          </div>

          <div class="qtnl-ctx">
            <div class="qtnl-ctx-title">倾听支持性</div>
            <div id="echats7" style="width: 100%; height: 350px"></div>
            <div class="content-sizi ctxcontent">
              您的{{ after[0][1].entityName }}得分为{{
                after[0][1].score
              }}分。{{ after[0][1].entityExplain }}
            </div>
            <!-- <div class="qingtingnengli-xx">
              {{ after[0][1].entityAd }}
            </div> -->
          </div>
        </div>
        <div class="gongqingshibdnl">
          <div class="ctx-title">
            <div class="title-ctx">共情式表达能力</div>
            <div class="title-fk"></div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              gap: 50px;
              margin: 20px 0;
            "
          >
            <div>
              <div
                class="gezi"
                v-for="(item1, index1) in 5 - after[1][0].score"
                :key="'gezi' + index1"
              ></div>

              <div
                class="langezi"
                v-for="(item2, index2) in after[1][0].score"
                :key="index2"
              ></div>
            </div>
            <div>
              <div
                class="gezi"
                v-for="(item3, index3) in 5 - before[1][0].score"
                :key="'gezi' + index3"
              ></div>

              <div
                class="langezi"
                v-for="(item3, index3) in before[1][0].score"
                :key="index3"
              ></div>
            </div>
          </div>

          <div>您的共情式表达能力得分：{{ after[1][0].score }}分</div>
          <div class="content-sizi ctxcontent">
            {{ after[1][0].entityExplain }}
          </div>
          <div class="gongqingshibdnlxx">{{ after[1][0].entityAd }}</div>
        </div>
        <div class="dawurenge">
          <div class="ctx-title">
            <div class="title-ctx">大五人格</div>
            <div class="title-fk"></div>
          </div>
          <div>您的大五人格结果：</div>
          <div id="echats8" style="width: 100%; height: 300px"></div>
          <div class="dwrgimg">
            <img
              class="dwrgimg"
              src="https://lesson.iapeap.com/images/EmotionalValue/dawurenge.png"
              alt=""
            />
          </div>

          <div class="wenxintishi">
            <div class="qingxuzuobiao">
              <img
                src="https://lesson.iapeap.com/images/EmotionalValue/gantanhao.png"
                alt=""
              />
              <div>说明：</div>
            </div>
            <div class="wenxintishi-ctx2">
              人格特质得分高低仅代表不同的行为方式和行动倾向，并无优劣高下之分。
            </div>
          </div>
          <div class="bluebox">
            <div class="bluetitle">各维度解析</div>
            <div class="blueitem">
              <div class="blueitem-blue">【{{ after[2][0].entityName }}】</div>
              <div class="blueitem-content">
                <div>
                  您的{{ after[2][0].entityName }}得分为{{ after[2][0].score }}
                  分
                </div>
                <div>{{ after[2][0].entityExplain }}</div>
                <div>{{ after[2][0].intro }}</div>
              </div>
            </div>
            <div class="blueitem">
              <div class="blueitem-blue">【{{ after[2][1].entityName }}】</div>
              <div class="blueitem-content">
                <div>
                  您的{{ after[2][1].entityName }}得分为{{ after[2][1].score }}
                  分
                </div>
                <div>{{ after[2][1].entityExplain }}</div>
                <div>{{ after[2][1].intro }}</div>
              </div>
            </div>
            <div class="blueitem">
              <div class="blueitem-blue">【{{ after[2][2].entityName }}】</div>
              <div class="blueitem-content">
                <div>
                  您的{{ after[2][2].entityName }}得分为{{ after[2][2].score }}
                  分
                </div>
                <div>{{ after[2][2].entityExplain }}</div>
                <div>{{ after[2][2].intro }}</div>
              </div>
            </div>
            <div class="blueitem">
              <div class="blueitem-blue">【{{ after[2][3].entityName }}】</div>
              <div class="blueitem-content">
                <div>
                  您的{{ after[2][3].entityName }}得分为{{ after[2][3].score }}
                  分
                </div>
                <div>{{ after[2][3].entityExplain }}</div>
                <div>{{ after[2][3].intro }}</div>
              </div>
            </div>
            <div class="blueitem">
              <div class="blueitem-blue">【{{ after[2][4].entityName }}】</div>
              <div class="blueitem-content">
                <div>
                  您的{{ after[2][4].entityName }}得分为{{ after[2][4].score }}
                  分
                </div>
                <div>{{ after[2][4].entityExplain }}</div>
                <div>{{ after[2][4].intro }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="cankaowenxian">
          <div>
            [1]Montag C, Gallinat J, Heinz A. Theodor Lipps and the Concept of
            Empathy: 1851-1914. American Journal Psychiatry, 2008, 165: 1261.
          </div>
          <div>
            [2]Preston, S. D., & de Waal, F. B. M. (2002). Empathy: Its ultimate
            and proximate bases. Behavioral and Brain Sciences, 25(1), 1-20.
          </div>
          <div>
            [3]Shamay-Tsoory, S. G. (2011). The neural bases for empathy.
            Neuroscientist, 17(1), 18-24.
          </div>
          <div>
            [4]Gladstein G, Feldstein J. Using filmto increase counselor
            empathic experiences. Counselor Education andSupervision, 1983, 23:
            125-132.
          </div>
          <div>
            [5]Decety, J., Bartal, I. B.,Uzefovsky, F., & Knafo-Noam, A. (2016).
            Empathy as a driver ofprosocial behaviour: Highly conserved
            neurobehavioural mechanisms across species. Philosophical
            Transactions of the Royal Society B-Biological Sciences, 371(1686),
            11.
          </div>
          <div>
            [6]Gambin Malgorzata, SharpCarla. Relations between empathy and
            anxiety dimensions in inpatientadolescents[J]. Anxiety, stress, and
            coping,2018,31(4):447-458.
          </div>
          <div>
            [7]Hogan R. Development of an empathy scale[J]. Journal of
            Consulting & Clinical Psychology,1969,33(3):307-316.
          </div>
          <div>
            [8]Mehrabian, Epstein. Ameasure of emotional empathy[J]. Journal of
            Personality, 1972(22): 525-543.
          </div>
          <div>
            [9]Davis, M. H. A multidimensional approach to individual
            difffferences in empathy[J]. Journal of Personality & Social
            Psychology,1980,15(10):85.
          </div>
          <div>
            [10]吴静吉，詹志禹.年级、性别角色、人情取向与同理心的关系[D].
            台湾政治大学教育研究所,1987: 133-146.
          </div>
          <div>
            [11]潘孝富,孔康,赵斌强等.大学生共情能力结构及其问卷编制[J].心理研究,2010,3(05):73-78.
          </div>
          <div>
            [12] Cooper LO, Buchanan T. Interrater agreement in judgments of
            listening competency: an item-based analysis of the organizational
            listening survey[J]. Communication Research Reports, 1999, 16(1):
            48-54.
          </div>
          <div>
            [13] Cooper LO, Husband RL. Developing a model of organizational
            listening competency[J]. International Journal of Listening, 1993,
            7(1): 6-34.
          </div>
          <div>
            [14] Cooper LO, Buchanan T. Taking aim at good targets: inter-rater
            agreement of listening competency[J]. International Journal of
            Listening, 2003, 17(1): 88-114.
          </div>
          <div>
            [15]霍德盖茨.工作中的现代人际关系学[M]．北京：中国人民大学出版社，1989：399．
          </div>
          <div>
            [16]汪涛，李敏，孙鹏，等．医学生人际沟通能力与影响因素分析[J]．中国高等医学教育，2011，2：28-29．
          </div>
          <div>
            [17]任泽娟，李林，胡娅丽，等．护生倾听与沟通能力调查分析[J]．护理学杂志，2004，19(11)：57-59．
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { contrastResult } from "../../api/login";
import * as echarts from "echarts";

export default {
  data() {
    return {
      id: "",
      subId: "", //再测一次ID
      subjectId: "",
      after: [],
      before: [],
    };
  },

  computed: {
    formattedText() {
      return this.result[2][0].entityExplain.replace(/\n/g, "<br>");
    },
  },
  mounted() {
    console.log("this.$route.query.id", this.$route.query.id);
    this.subjectId = this.$route.query.id;
    if (this.subjectId) {
      setTimeout(() => {
        this.getcontrastResult();
      }, 100);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getcontrastResult() {
      Toast.loading({
        message: "正在生成报告",
        forbidClick: true,
        duration: 0,
      });

      let data = {
        subjectId: this.subjectId,
        userId: window.localStorage.getItem("userId"),
      };
      contrastResult(data)
        .then((res) => {
          this.after = res.after;
          this.before = res.before;
          this.subId = res.subId;

          Toast.clear();
        })
        .catch((error) => {
          Toast.clear();
          Toast.fail("该报告无法对比");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        })
        .finally(() => {
          this.createdChat2();
          this.createdChat1();
          this.createdChat3();
          this.createdChat4();
          this.createdChat5();
          this.createdChat6();
          this.createdChat7();
          this.createdChat8();
        });
    },

    createdChat1() {
      let angle = 0; //角度，用来做简单的动画效果的
      let value = 3;
      let option1 = {
        backgroundColor: "#fff",
        title: {
          text:
            (
              (this.before[3][6].score +
                this.before[3][7].score +
                this.before[3][8].score) /
              3
            ).toFixed(2) + "分",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 22,
                color: "#333",
              },

              c: {
                fontSize: 22,
                color: "#333",
                // padding: [5,0]
              },
            },
          },
        },
        series: [
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((0 + angle) * Math.PI) / 180,
                  endAngle: ((90 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((180 + angle) * Math.PI) / 180,
                  endAngle: ((270 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((270 + -angle) * Math.PI) / 180,
                  endAngle: ((40 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((90 + -angle) * Math.PI) / 180,
                  endAngle: ((220 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 90 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //粉
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", //绿点
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 270 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //绿
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "吃猪肉频率",
            type: "pie",
            radius: ["58%", "45%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#4397ed", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#4397ed", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value:
                  5 -
                  (this.before[3][6].score +
                    this.before[3][7].score +
                    this.before[3][8].score) /
                    3,
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#93ccf3",
                  },
                },
              },
            ],
          },

          {
            name: "",
            type: "gauge",
            radius: "58%",
            center: ["50%", "50%"],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 60,
              lineStyle: {
                width: 5,
                color: "#ffff",
              },
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
        ],
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }

      function draw() {
        angle = angle + 3;
        myChart1.setOption(option1, true);
        //window.requestAnimationFrame(draw);
      }

      setInterval(function () {
        //用setInterval做动画感觉有问题
        draw();
      }, 100);

      let myChart1 = echarts.init(document.querySelector("#echats1"));
      myChart1.setOption(option1);
    },
    createdChat2() {
      let angle = 0; //角度，用来做简单的动画效果的
      let value = 3;
      let option2 = {
        backgroundColor: "#fff",
        title: {
          text:
            (
              (this.after[3][6].score +
                this.after[3][7].score +
                this.after[3][8].score) /
              3
            ).toFixed(2) + "分",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 22,
                color: "#333",
              },

              c: {
                fontSize: 22,
                color: "#333",
                // padding: [5,0]
              },
            },
          },
        },
        series: [
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((0 + angle) * Math.PI) / 180,
                  endAngle: ((90 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((180 + angle) * Math.PI) / 180,
                  endAngle: ((270 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((270 + -angle) * Math.PI) / 180,
                  endAngle: ((40 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((90 + -angle) * Math.PI) / 180,
                  endAngle: ((220 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 90 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //粉
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", //绿点
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 270 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //绿
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "吃猪肉频率",
            type: "pie",
            radius: ["58%", "45%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#4397ed", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#4397ed", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value:
                  5 -
                  (this.after[3][6].score +
                    this.after[3][7].score +
                    this.after[3][8].score) /
                    3,
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#93ccf3",
                  },
                },
              },
            ],
          },

          {
            name: "",
            type: "gauge",
            radius: "58%",
            center: ["50%", "50%"],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 60,
              lineStyle: {
                width: 5,
                color: "#ffff",
              },
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
        ],
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }

      function draw() {
        angle = angle + 3;
        myChart2.setOption(option2, true);
        //window.requestAnimationFrame(draw);
      }

      setInterval(function () {
        //用setInterval做动画感觉有问题
        draw();
      }, 100);

      let myChart2 = echarts.init(document.querySelector("#echats2"));
      myChart2.setOption(option2);
    },

    createdChat3() {
      let option3 = {
        backgroundColor: "#fff",
        tooltip: {
          //提示框组件
          trigger: "axis",
          formatter: "{b}<br />{a0}: {c0}<br />{a1}: {c1}",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "10%",
          top: "30%",
          //	padding:'0 0 10 0',
          containLabel: true,
        },
        legend: {
          //图例组件，颜色和名字
          right: "10%",
          top: "10%",
          itemGap: 10,
          itemWidth: 10,
          itemHeight: 10,
          data: [
            {
              name: "首次测评结果",
              //icon:'image://../wwwroot/js/url2.png', //路径
            },
            {
              name: "本次测评结果",
            },
          ],
          textStyle: {
            color: "#a8aab0",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: "category",
            data: ["情绪识别", "共情接纳", "共情行为"],
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              //		interval: 0,//设置为 1，表示『隔一个标签显示一个标签』
              //	margin:15,
              textStyle: {
                color: "#333",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
              rotate: 0,
            },
            axisTick: {
              //坐标轴刻度相关设置。
              show: false,
            },
            axisLine: {
              //坐标轴轴线相关设置
              lineStyle: {
                color: "#fff",
                opacity: 0.2,
              },
            },
            splitLine: {
              //坐标轴在 grid 区域中的分隔线。
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#fff"],
                opacity: 0.06,
              },
            },
          },
        ],
        series: [
          {
            name: "首次测评结果",
            type: "bar",
            data: [
              this.after[3][6].score,
              this.after[3][7].score,
              this.after[3][8].score,
            ],
            barWidth: 25,
            barGap: 0, //柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#4CA0EF",
                  },
                ]),
                barBorderRadius: 0,
                borderWidth: 0,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#333",
              },
            },
          },
          {
            name: "本次测评结果",
            type: "bar",
            data: [
              this.before[3][6].score,
              this.before[3][7].score,
              this.before[3][8].score,
            ],
            barWidth: 25,
            barGap: 0.2, //柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#9FD0FF",
                  },
                ]),
                barBorderRadius: 0,
                borderWidth: 0,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#333",
              },
            },
          },
        ],
      };

      let myChart3 = echarts.init(document.querySelector("#echats3"));
      myChart3.setOption(option3);
    },

    createdChat4() {
      let angle = 0; //角度，用来做简单的动画效果的
      let value = 3;
      let option4 = {
        backgroundColor: "#fff",
        title: {
          text:
            (
              (this.before[0][1].score +
                this.before[0][1].score +
                this.before[0][2].score) /
              3
            ).toFixed(2) + "分",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 22,
                color: "#333",
              },

              c: {
                fontSize: 22,
                color: "#333",
                // padding: [5,0]
              },
            },
          },
        },
        series: [
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((0 + angle) * Math.PI) / 180,
                  endAngle: ((90 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((180 + angle) * Math.PI) / 180,
                  endAngle: ((270 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((270 + -angle) * Math.PI) / 180,
                  endAngle: ((40 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((90 + -angle) * Math.PI) / 180,
                  endAngle: ((220 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 90 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //粉
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", //绿点
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 270 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //绿
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "吃猪肉频率",
            type: "pie",
            radius: ["58%", "45%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#4397ed", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#4397ed", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value:
                  5 -
                  (
                    (this.before[0][1].score +
                      this.before[0][1].score +
                      this.before[0][2].score) /
                    3
                  ).toFixed(2),
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#93ccf3",
                  },
                },
              },
            ],
          },

          {
            name: "",
            type: "gauge",
            radius: "58%",
            center: ["50%", "50%"],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 60,
              lineStyle: {
                width: 5,
                color: "#ffff",
              },
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
        ],
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }

      function draw() {
        angle = angle + 3;
        myChart4.setOption(option4, true);
        //window.requestAnimationFrame(draw);
      }

      setInterval(function () {
        //用setInterval做动画感觉有问题
        draw();
      }, 100);

      let myChart4 = echarts.init(document.querySelector("#echats4"));
      myChart4.setOption(option4);
    },
    createdChat5() {
      let angle = 0; //角度，用来做简单的动画效果的
      let value = 3;
      let option5 = {
        backgroundColor: "#fff",
        title: {
          text:
            (
              (this.after[0][1].score +
                this.after[0][1].score +
                this.after[0][2].score) /
              3
            ).toFixed(2) + "分",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 22,
                color: "#333",
              },

              c: {
                fontSize: 22,
                color: "#333",
                // padding: [5,0]
              },
            },
          },
        },
        series: [
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((0 + angle) * Math.PI) / 180,
                  endAngle: ((90 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((180 + angle) * Math.PI) / 180,
                  endAngle: ((270 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((270 + -angle) * Math.PI) / 180,
                  endAngle: ((40 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((90 + -angle) * Math.PI) / 180,
                  endAngle: ((220 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#93ccf3",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 90 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //粉
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", //绿点
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 270 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#93ccf3", //绿
                  fill: "#93ccf3",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "吃猪肉频率",
            type: "pie",
            radius: ["58%", "45%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#4397ed", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#4397ed", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value:
                  5 -
                  (
                    (this.after[0][1].score +
                      this.after[0][1].score +
                      this.after[0][2].score) /
                    3
                  ).toFixed(2),
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#93ccf3",
                  },
                },
              },
            ],
          },

          {
            name: "",
            type: "gauge",
            radius: "58%",
            center: ["50%", "50%"],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 60,
              lineStyle: {
                width: 5,
                color: "#ffff",
              },
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
        ],
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }

      function draw() {
        angle = angle + 3;
        myChart5.setOption(option5, true);
        //window.requestAnimationFrame(draw);
      }

      setInterval(function () {
        //用setInterval做动画感觉有问题
        draw();
      }, 100);

      let myChart5 = echarts.init(document.querySelector("#echats5"));
      myChart5.setOption(option5);
    },

    createdChat6() {
      let option6 = {
        backgroundColor: "#fff",
        tooltip: {
          //提示框组件
          trigger: "axis",
          formatter: "{b}<br />{a0}: {c0}<br />{a1}: {c1}",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "10%",
          top: "30%",
          //	padding:'0 0 10 0',
          containLabel: true,
        },
        legend: {
          //图例组件，颜色和名字
          right: "10%",
          top: "10%",
          itemGap: 10,
          itemWidth: 10,
          itemHeight: 10,
          data: [
            {
              name: "首次测评结果",
              //icon:'image://../wwwroot/js/url2.png', //路径
            },
            {
              name: "本次测评结果",
            },
          ],
          textStyle: {
            color: "#a8aab0",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: "category",
            data: ["首次测评结果", "本次测评结果"],
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              //		interval: 0,//设置为 1，表示『隔一个标签显示一个标签』
              //	margin:15,
              textStyle: {
                color: "#333",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
              rotate: 0,
            },
            axisTick: {
              //坐标轴刻度相关设置。
              show: false,
            },
            axisLine: {
              //坐标轴轴线相关设置
              lineStyle: {
                color: "#fff",
                opacity: 0.2,
              },
            },
            splitLine: {
              //坐标轴在 grid 区域中的分隔线。
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 16,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#fff"],
                opacity: 0.06,
              },
            },
          },
        ],
        series: [
          {
            name: "首次测评结果",
            type: "bar",
            data: [this.before[0][0].score, ""],
            barWidth: 0,
            barGap: 0, //柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#4CA0EF",
                  },
                ]),
                barBorderRadius: 0,
                borderWidth: 0,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#333",
              },
            },
          },
          {
            name: "本次测评结果",
            type: "bar",
            data: ["", this.after[0][0].score],
            barWidth: 0,
            barGap: 0, //柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#9FD0FF",
                  },
                ]),
                barBorderRadius: 0,
                borderWidth: 0,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#333",
              },
            },
          },
        ],
      };

      let myChart6 = echarts.init(document.querySelector("#echats6"));
      myChart6.setOption(option6);
    },

    createdChat7() {
      let option7 = {
        backgroundColor: "#fff",
        tooltip: {
          //提示框组件
          trigger: "axis",
          formatter: "{b}<br />{a0}: {c0}<br />{a1}: {c1}",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "10%",
          top: "30%",
          //	padding:'0 0 10 0',
          containLabel: true,
        },
        legend: {
          //图例组件，颜色和名字
          right: "10%",
          top: "10%",
          itemGap: 10,
          itemWidth: 10,
          itemHeight: 10,
          data: [
            {
              name: "首次测评结果",
              //icon:'image://../wwwroot/js/url2.png', //路径
            },
            {
              name: "本次测评结果",
            },
          ],
          textStyle: {
            color: "#a8aab0",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: "category",
            data: ["情绪识别", "共情接纳"],
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              //		interval: 0,//设置为 1，表示『隔一个标签显示一个标签』
              //	margin:15,
              textStyle: {
                color: "#333",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
              rotate: 0,
            },
            axisTick: {
              //坐标轴刻度相关设置。
              show: false,
            },
            axisLine: {
              //坐标轴轴线相关设置
              lineStyle: {
                color: "#fff",
                opacity: 0.2,
              },
            },
            splitLine: {
              //坐标轴在 grid 区域中的分隔线。
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 16,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#fff"],
                opacity: 0.06,
              },
            },
          },
        ],
        series: [
          {
            name: "首次测评结果",
            type: "bar",
            data: [this.before[0][1].score, ""],
            barWidth: 0,
            barGap: 0, //柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#4CA0EF",
                  },
                ]),
                barBorderRadius: 0,
                borderWidth: 0,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#333",
              },
            },
          },
          {
            name: "本次测评结果",
            type: "bar",
            data: ["", this.after[0][1].score],
            barWidth: 0,
            barGap: 0, //柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#9FD0FF",
                  },
                ]),
                barBorderRadius: 0,
                borderWidth: 0,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#333",
              },
            },
          },
        ],
      };

      let myChart7 = echarts.init(document.querySelector("#echats7"));
      myChart7.setOption(option7);
    },

    createdChat8() {
      //大五人格
      let option8 = {
        backgroundColor: "#FFF",
        radar: {
          name: {
            textStyle: {
              color: "#999999",
            },
          },
          splitArea: {
            areaStyle: {
              color: ["#fff", "#fff", "#fff", "#fff"],
              shadowColor: "red",
            },
          },
          indicator: [
            { name: "神经质", max: 50 },
            { name: "开放性", max: 50 },
            { name: "外倾性", max: 50 },
            { name: "宜人性", max: 50 },
            { name: "尽责性", max: 50 },
          ],
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 0,

            label: {
              show: true,
            },
            data: [
              {
                value: [
                  this.after[2][0].score,
                  this.after[2][1].score,
                  this.after[2][2].score,
                  this.after[2][3].score,
                  this.after[2][4].score,
                ],
                name: "DATA",
                itemStyle: {
                  normal: {
                    color: "rgba(5, 128, 242, 0.5)",
                  },
                },
                areaStyle: {
                  normal: {
                    color: "rgba(76, 160, 239, 0.30)",
                  },
                },
              },
            ],
          },
          {
            type: "radar",
            label: {
              show: true,
            },
            data: [
              {
                value: [29, 21, 23, 27, 27],
                name: "DATA",
                itemStyle: {
                  normal: {
                    color: "RGBA(104, 218, 149, 0.2)",
                  },
                },
              },
            ],
          },
          {
            type: "radar",
            label: {
              show: true,
            },
            data: [
              {
                value: [38, 32, 39, 37, 37],
                name: "DATA",
                itemStyle: {
                  normal: {
                    color: "RGBA(255, 191, 229, 0.2)",
                  },
                },
              },
            ],
          },
        ],
      };
      let myChart8 = echarts.init(document.querySelector("#echats8"));
      myChart8.setOption(option8);
    },
  },
};
</script>

<style lang="less">
#echats1 {
  width: 100%;
  height: 200px;
  background: transparent;
}
#echats2 {
  width: 100%;
  height: 200px;
  background: transparent;
}
#echats5 {
  width: 150px;
  height: 150px;
  background: transparent;
}
#echats6 {
  width: 150px;
  height: 150px;
  background: transparent;
}
#echats4 {
  margin-top: 40px;
  width: 100%;
  height: 250px;
  background: transparent;
}
.echatsfenkai {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box {
  background-color: #f4fafe;
  min-height: 100vh;

  .margintop20 {
    margin-top: 20;
  }
  .ctxtitle {
    font-size: 15px;
    font-weight: 500;
    color: #629ee9;
    line-height: 21px;
  }
  .ctxcontent {
    box-sizing: border-box;
    padding: 5px 20px;
    margin-top: 5px;
    text-indent: 20px;
    font-size: 13px;
    font-weight: 300;
    color: #000025;
    line-height: 20px;
  }
  .testresult {
    padding: 20px;
    box-sizing: border-box;
    .content-sizi {
      text-indent: 20px;
      font-size: 13px;
      font-weight: 400;
      color: #000025;
      line-height: 20px;
    }
    .testresult-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 40px;
      box-sizing: border-box;
      font-size: 30px;
      font-weight: 500;
      color: #000025;
      img {
        width: 70px;
        height: 77px;
      }
    }
    .cankaowenxian {
      border-radius: 8px;
      border: 1px solid #c7c7c7;
      padding: 20px;
      box-sizing: border-box;
      font-size: 10px;
      font-weight: 100;
      color: #000025;
      line-height: 18px;
    }
    .ctx-title {
      .title-ctx {
        z-index: 999;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        color: #000025;
        line-height: 25px;
      }
      .title-fk {
        width: 35px;
        height: 5px;
        background: #629ee9;
        position: relative;
        bottom: 8px;
        z-index: 998;
        transform: skew(-25deg);
      }
    }

    .gongqingnengli {
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px #d8e2ed;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
      backdrop-filter: blur(3px);
      .gongqingnenglictx {
        text-indent: 20px;
        font-size: 13px;
        font-weight: 400;
        color: #000025;
        line-height: 20px;
      }
      .gongqingnenglictx2 {
        margin-top: 20px;
        background: #f6fbfd;
        border-radius: 8px;
        .ctx2-title {
          width: 105px;
          height: 33px;
          background: #629ee9;
          border-radius: 8px 0px 8px 0px;
          color: #fff;
          line-height: 33px;
          text-align: center;
        }
        .ctx2-xubian {
          border: 2px dotted #629ee9;
          padding: 20px;
          box-sizing: border-box;
          margin: 10px 20px;
          font-size: 13px;
          font-weight: 500;
          color: #000025;
          line-height: 20px;
        }
      }
    }
    .qingtingnengli {
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px #d8e2ed;
      border: 1px solid;
      padding: 20px;
      box-sizing: border-box;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
      backdrop-filter: blur(3px);
      .qtnl-ctx {
        width: 296px;
        background: #f6fbfd;
        border-radius: 8px;
        .qtnl-ctx-title {
          width: 105px;
          height: 33px;
          background: #629ee9;
          border-radius: 8px 0px 8px 0px;
          line-height: 33px;
          text-align: center;
          color: #fff;
        }
      }
      .qingtingnengli-xx {
        border: 2px dotted #629ee9;
        padding: 20px;
        box-sizing: border-box;
        margin: 10px 20px;
        font-size: 13px;
        font-weight: 500;
        color: #000025;
        line-height: 20px;
      }
    }
    .gongqingshibdnl {
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px #d8e2ed;
      border-radius: 8px;
      backdrop-filter: blur(3px);
      padding: 20px;
      box-sizing: border-box;
      .gezi {
        margin: 0 auto;
        width: 80px;
        height: 10px;
        background: rgba(229, 236, 243, 0.7);
        border-radius: 4px;
        border: 1px solid #ced6de;
        margin-top: 5px;
      }
      .langezi {
        margin: 0 auto;
        width: 80px;
        height: 10px;
        background: rgba(98, 158, 233, 0.5);
        border-radius: 4px;
        border: 1px solid #629ee9;
        margin-top: 5px;
      }
      .gongqingshibdnlxx {
        border: 2px dotted #629ee9;
        padding: 20px;
        box-sizing: border-box;
        margin: 10px 20px;
        font-size: 13px;
        font-weight: 500;
        color: #000025;
        line-height: 20px;
      }
    }
    .dawurenge {
      margin-top: 20px;
      padding: 10px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px #d8e2ed;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
      backdrop-filter: blur(3px);
      .wenxintishi {
        border: 3px dotted #c7c7c7;
        margin-top: 10px;
        .qingxuzuobiao {
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 12px;
          }
        }
        .wenxintishi-ctx2 {
          padding: 0 10px;
          font-size: 12px;
          margin-bottom: 8px;
          color: #a09c9b;
          box-sizing: border-box;
        }
      }
    }
  }
  .bluebox {
    background: #f6fbfd;
    border-radius: 8px 0px 8px 0px;

    margin-top: 20px;
    .bluetitle {
      width: 110px;
      height: 33px;
      background: #629ee9;
      border-radius: 8px 0px 8px 0px;
      color: #fff;
      line-height: 33px;
      text-align: center;
    }
    .blueitem {
      padding: 10px 20px;
      box-sizing: border-box;
      .blueitem-blue {
        height: 21px;
        font-size: 15px;
        font-weight: 500;
        color: #629ee9;
        line-height: 21px;
      }
      .blueitem-content {
        font-size: 13px;
        font-weight: 300;
        color: #000025;
        line-height: 20px;
      }
    }
  }
  .testbtn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 20px;
    button {
      background: #629ee9;
      height: 40px;
      width: 120px;
      border: 0;
      line-height: 40px;
      color: #fff;
      border-radius: 25px;
    }
  }
  .dwrgimg {
    display: flex;
    justify-content: center;
    img {
      width: 224px;
      height: 14px;
    }
  }
}
.navbar {
  background-color: #ededed;
}
.van-nav-bar .van-icon {
  color: #464646;
}
</style>
